import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc11",
  "Nov 1, 2024",
  `
- General
  - Improvements
    - During the intro/outro cinematics you'll now play one of your equipped emotes if you have one
  - Fixes
    - Fixed an issue preventing customizations from showing on the end game cinematic
    - Fixed an issue with the flare tooltip
    - Fixed an issue where you could cast spells on dead targets
    - Fixed an issue causing the global cooldown tracker settings not to work properly
- Balance
  - General
    - Powerup Orbs now must be killed before the powerup is received and have 75 health.
    - Interdimensional Portals no longer increases movement speed.
    - Increased the cast time of the 4th Frost Crystal cast to 1.8 sec (up from 1 sec)
    - Increased the cast time of the 4th Fire Crystl cast to 1.8 sec (up from 1.5 sec)
    - Reduced the duration of crystals to 8 seconds (down from 16 seconds)
    - "Improved Righteous Shield" now only increases the targets movement speed by 25% (down from 50%)
    - "Soothing Souls" now restores 75% of the health taken from teammates after 4 seconds (changed from 100% after 6 seconds)
    - Requisite Confession now has a 35 second CD (down from 60 sec)
    - Righteous Hindrance now slows the target by 15% and has a 15 yard range (changed from no slow and 10 yard range)
    - Fixed an issue causing Righteous Hindrance not granding a stack of Divine Empowerment
    - Mystical Brilliance now heals for 12 on Smiter (up from 8)
    - Fixed an issue causing Sedative Shot to sometimes fully interrupt targets for 6 seconds (w/ the talent) who weren't actually casting
    - Rupture base damage changed to 1/2/4/6/11 (from 1/2/4/7/13)
    - The Ruptured Organ talent now also increases the damage dealt by Rupture by 10% (changed from previously lowering the damage per tick)
    - Reduced the Bruiser passive momentum from jumping from 14 to 12
    - Reduced the Warmth general talent from 50% reduction in Slows and Snares to 30%
`
);

export default Notes;
