import { PageLayout } from "./PageLayout";
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase/auth.js";
import { useEffect, useState } from "react";
import callFirebaseFunction from "./firebase/callFirebaseFunction";
import { Button } from "./LandingPage.js";
import { Link } from "react-router-dom";
import { Spinner } from "@radix-ui/themes";

export const inputStyle = {
  outline: "none",
  border: "none",
  borderRadius: 8,
  background: "rgba(255, 255, 255, 0.05)",
  color: "white",
  padding: "8px 12px",
};

async function completeSignIn() {
  let email = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    alert(
      "Unable to find email in localStorage. Please try again. You must use the same device to open the link as you used when you entered your email."
    );
    return;
  }
  try {
    await signInWithEmailLink(auth, email, window.location.href);
    return true;
  } catch (error) {
    console.log("error", error);
  }

  return false;
}

function SignUpForm() {
  const [email, setEmail] = useState("");
  const [state, setState] = useState(null);

  if (state === "sent") {
    return "An email has been sent to you with link you can use to log in.";
  }

  const submitFn = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      setState("loading");
      await sendSignInLinkToEmail(auth, email, {
        url: location.protocol + "//" + location.host + location.pathname,
        handleCodeInApp: true,
      });
      setState("sent");

      window.localStorage.setItem("emailForSignIn", email);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        width: "100%",
        alignItems: "center",
      }}
    >
      <h2 style={{ margin: 0 }}>CREATOR PORTAL SIGN IN</h2>

      <form onSubmit={submitFn} style={{ display: "flex", gap: 8 }}>
        <input
          type="email"
          style={{ ...inputStyle, width: 240 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email..."
          disabled={state === "loading"}
        />
        {state === "loading" ? (
          <Button
            key="loading"
            style={{
              borderRadius: 12,
              width: 40,
              padding: 8,
              justifyContent: "center",
            }}
            spinIcon={true}
            icon={<i className="fas fa-spinner" />}
          />
        ) : (
          <Button
            key="submit"
            onClick={submitFn}
            style={{
              borderRadius: 12,
              width: 40,
              padding: 8,
              justifyContent: "center",
            }}
            icon={<i className="fas fa-chevron-right" />}
          />
        )}
      </form>
      <div
        style={{
          textAlign: "center",
          width: 600,
          maxWidth: "100%",
          fontSize: 14,
          color: "#ddd",
        }}
      >
        If you have received an invite to the Creator Program via email, enter
        the email address you received it at and we'll send you a link to log
        into the Creator Portal. If you have not received an invite, you will be
        unable to use the Creator Portal.
      </div>
      <div>
        <Button
          icon={<i className="fas fa-info-circle" />}
          style={{ borderRadius: 12, padding: "8px 12px" }}
          link="/creatorprogram"
        >
          Creator Program Information
        </Button>
      </div>
    </div>
  );
}

function DataBox({ label, children }) {
  return (
    <div
      style={{
        padding: 20,
        background: "rgba(255, 255, 255, 0.05)",
        borderRadius: 12,
        flexGrow: 1,
        textTransform: "uppercase",
      }}
    >
      <div style={{ fontSize: 14 }}>{label}</div>
      <div style={{ fontSize: 38, fontWeight: 800 }}>{children}</div>
    </div>
  );
}

function formatUsdCents(cents) {
  const centsStr = cents.toString().padStart(3, "0");
  const decimalIndex = centsStr.length - 2;
  const usdStr =
    centsStr.slice(0, decimalIndex) + "." + centsStr.slice(decimalIndex);

  return `$${usdStr} USD`;
}

function OrderRow({ order }) {
  return (
    <>
      <div>{new Date(order.date._seconds * 1000).toLocaleString()}</div>
      <div>{order.orderId}</div>
      <div style={{ textAlign: "right" }}>
        {formatUsdCents(order.costUSDCents)}
      </div>
      <div style={{ textAlign: "right" }}>
        {formatUsdCents(order.payoutUSDCents)}
      </div>
      {order.verified ? (
        <div
          style={{
            color: "#3ec832",
            fontWeight: "bold",
            fontSize: 14,
            display: "flex",
            gap: 4,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          VERIFIED
          <i className="fas fa-check-circle" />
        </div>
      ) : (
        <div
          style={{
            color: "rgb(15, 100, 234)",
            fontWeight: "bold",
            fontSize: 14,
            display: "flex",
            gap: 4,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          PENDING
          <i className="fas fa-sync" />
        </div>
      )}
    </>
  );
}

function TransactionTable() {
  const [data, setData] = useState(null);

  useEffect(async () => {
    const data = await callFirebaseFunction("getOrders");
    setData(data);
  }, []);

  if (data == null) {
    return <Spinner />;
  }

  if (data.orders.length === 0) {
    return "No transactions yet... Share your creator code with your players and encourage them to enter it!";
  }

  return (
    <div
      style={{
        display: "grid",
        gap: 4,
        gridTemplateColumns: "1fr 1fr 1fr 1fr 120px",
      }}
    >
      <div style={{ fontSize: 20, fontWeight: "bold" }}>DATE</div>
      <div style={{ fontSize: 20, fontWeight: "bold" }}>ORDER ID</div>
      <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "right" }}>
        TOTAL AMOUNT
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "right" }}>
        YOUR PAYOUT
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "right" }}>
        STATUS
      </div>

      {data.orders.map((order) => (
        <OrderRow key={order.orderId} order={order} />
      ))}
    </div>
  );
}

function PaypalEmail({ currentEmail }) {
  const [editingPaypal, setEditingPaypal] = useState(false);
  const [paypalEmailForEditing, setPaypalEmailForEditing] = useState(
    currentEmail ?? ""
  );
  const [shownPaypalEmail, setShownPaypalEmail] = useState(currentEmail);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitPaypalForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsSubmitting(true);

    const result = await callFirebaseFunction("setPaypalEmail", {
      paypal: paypalEmailForEditing,
    });

    if (!result) {
      // TODO do something better
      alert("Failed to set paypal email, please try again");
    } else {
      setShownPaypalEmail(paypalEmailForEditing);
    }

    setEditingPaypal(false);
    setIsSubmitting(false);
  };

  const button = isSubmitting ? (
    <Button
      key="submitting"
      style={{
        borderRadius: 8,
        width: 40,
        padding: 8,
        justifyContent: "center",
        color: "#ccc",
      }}
      spinIcon={true}
      icon={<i className="fas fa-spinner" />}
    />
  ) : editingPaypal ? (
    <Button
      key="submit"
      onClick={submitPaypalForm}
      style={{
        borderRadius: 8,
        width: 40,
        padding: 8,
        justifyContent: "center",
      }}
      icon={<i className="fas fa-chevron-right" />}
    />
  ) : (
    <Button
      key="edit"
      onClick={() => {
        setEditingPaypal(true);
      }}
      style={{
        borderRadius: 8,
        width: 40,
        padding: 8,
        justifyContent: "center",
      }}
      icon={<i className="fas fa-edit" />}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
      }}
    >
      <div>
        <strong>PayPal Email:</strong>{" "}
        {editingPaypal ? (
          <form style={{ display: "inline-block" }} onSubmit={submitPaypalForm}>
            <input
              type="email"
              style={inputStyle}
              placeholder="Enter your PayPal email..."
              value={paypalEmailForEditing}
              onChange={(e) => setPaypalEmailForEditing(e.target.value)}
            />
          </form>
        ) : (
          shownPaypalEmail ?? "Not set yet..."
        )}
      </div>
      {button}
    </div>
  );
}

function SignOutButton() {
  return (
    <Button
      style={{ borderRadius: 8, padding: "8px 12px" }}
      icon={<i className="fas fa-sign-out-alt" />}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        signOut(auth);
      }}
    >
      Sign Out
    </Button>
  );
}

function SignedInPortal() {
  const [data, setData] = useState(null);

  useEffect(async () => {
    const data = await callFirebaseFunction("getCreatorInfo");
    setData(data);
  }, []);

  if (data == null) {
    return <Spinner />;
  }

  if (data === false) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          The email you used to sign up has not been invited to the Creator
          Program. <Link to="/creatorprogram">Learn More</Link>. If you think
          this is a mistake, please email support@ringoftitans.com.
        </div>
        <div>
          <SignOutButton />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 12,
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <DataBox label="Creator Code">{data.code.toUpperCase()}</DataBox>
        <DataBox label="Payout">{data.payoutPercent}%</DataBox>
        <DataBox label="Next Payout Amount">
          {formatUsdCents(data.windowEarningsUsdCents)}
        </DataBox>
        <DataBox label="Next Payout Date">
          {new Date(data.nextPayoutDate).toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          })}
        </DataBox>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 0.05)",
          borderRadius: 12,
          padding: 20,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
          }}
        >
          <h2 style={{ margin: 0, fontSize: 28 }}>ORDER LOG</h2>
          <p style={{ margin: 0 }}>
            A list of all orders made by players using your creator code.
          </p>
        </div>
        <TransactionTable />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 20,
          flexWrap: "wrap",
          background: "rgba(255, 255, 255, 0.05)",
          borderRadius: 12,
          padding: 8,
        }}
      >
        <PaypalEmail currentEmail={data.paypalEmail} />
        <Button
          icon={<i className="fas fa-info-circle" />}
          style={{ borderRadius: 8, padding: "8px 12px" }}
          link="/creatorprogram"
        >
          Program Information
        </Button>
        <SignOutButton />
      </div>
    </div>
  );
}

function CreatorPortalContent() {
  const [pageState, setPageState] = useState(null);

  useEffect(async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      await completeSignIn();
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("signedin");
      } else {
        setPageState("signup");
      }
    });
  }, [setPageState]);

  if (pageState == null) {
    return <Spinner />;
  }

  if (pageState === "signup") {
    return <SignUpForm />;
  }

  if (pageState === "signedin") {
    return <SignedInPortal />;
  }

  return "Unknown state";
}

export default function CreatorPortal() {
  return (
    <PageLayout
      label="Creator Portal"
      style={{ fontFamily: '"Montserrat", sans-serif' }}
    >
      <CreatorPortalContent />
    </PageLayout>
  );
}
