%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerPaladin
  m_EditorClassIdentifier: 
  unitModel: {fileID: 1343574854534011583, guid: f06c9874bdb4a2645988576db6dd4dd2,
    type: 3}
  heroClass: 7
  isHealer: 1
  level: 0
  attackSpeed: 1
  baseHP: 198
  unitName: Anthriel
  unitSpec: Lightbringer
  icon: {fileID: 2800000, guid: e20bf3af84b72884a800b72e7c259fdd, type: 3}
  backgroundColor: {r: 0.8962264, g: 0.45149517, b: 0.8414983, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HEALER
  description: Anthriel, a revered Lightbringer in the Ring of Titans, epitomizes
    the embodiment of divine healing and support. With her mastery over Holy magic,
    she stands as a beacon of hope amidst the chaos of battle. While her prowess
    lies in delivering massive healing and bolstering her comrades, she lacks the
    means to inflict significant damage upon her adversaries. Yet, her unwavering
    dedication to her allies and her unparalleled ability to restore vitality ensure
    that her team remains resilient in the face of adversity. In the arena, Anthriel's
    presence is not just a force for survival but a testament to the power of compassion
    and divine intervention.
  passiveIcon: {fileID: 21300000, guid: 7b54730f0ef7d7c43aafbf4313f936cc, type: 3}
  passiveName: Divine Perserverance
  passiveDescription: Reduces the duration of <b>Incapacitates</b> on you by <b>12%</b>.
