%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: PowerBurn
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 7f1c197a232a3d345aaa061a8840dedd,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 8300000, guid: 2c783095a3b2c8a4fa8cae902699c577, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 309
  castDelay: 0
  animationSpeed: 0.33
  animationDuration: 2
  skipCooldownOnChannel: 0
  spellName: Power Burn
  description: Deal <b>2</b> damage to the target and reduce the amount of outgoing
    healing & shielding they do by <b>2.5%</b> per stack for <b>30</b> seconds. Stacks
    up to <b>30</b> times.
  icon: {fileID: 2800000, guid: 725e09d20b39ab947a7ab585d759d817, type: 3}
  usableBy: 02000000
  cooldown: 0
  castTime: 1.8
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
