%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerBruiserRogue
  m_EditorClassIdentifier: 
  unitModel: {fileID: 1737313431337791715, guid: 503aab51049ed98479cdd715045cb3ba,
    type: 3}
  heroClass: 10
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 194
  unitName: Volen
  unitSpec: Bruiser
  icon: {fileID: 2800000, guid: 2978e214a62beee449d7882b1a12a347, type: 3}
  backgroundColor: {r: 0.8773585, g: 0.24334282, b: 0.24967617, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 0322a772bc4868f428fa79cd35faec60, type: 3}
  type: BRUISER
  description: Volen is a Bruiser who commands respect within the Ring of Titans.
    His combat style is defined by relentless aggression and unyielding resolve,
    dealing consistent damage to his opponents without showing any sign of backing
    down. What sets him apart is his remarkable ability to stun and control adversaries
    with precision, slowly wearing his opponents down with his determination. With
    his imposing presence and mastery over controlling the flow of combat, Volen
    stands as a force to be reckoned with in the arena, leaving a trail of defeated
    foes in his wake.
  passiveIcon: {fileID: 21300000, guid: c95e43e176074c548b752c46fa9b9b25, type: 3}
  passiveName: Double Jump
  passiveDescription: You can jump again while in the air after jumping from the
    ground. Your second jump also gives a burst of momentum in your facing direction.
