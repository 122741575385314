%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerMage
  m_EditorClassIdentifier: 
  unitModel: {fileID: 5226127952259784134, guid: 365d9e4be31e2ed4c851d634392055f8,
    type: 3}
  heroClass: 0
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 146
  unitName: Azora
  unitSpec: Cryomancer
  icon: {fileID: 2800000, guid: 8c7a31f97aa2ba54084540434eba412b, type: 3}
  backgroundColor: {r: 0.2392157, g: 0.4745098, b: 0.654902, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: a231144642eabe64691435d0172b660b, type: 3}
  type: MAGE
  description: Azora is a Cryomancer who wields frost magic with unparalleled finesse.
    With a swift flick of her hand, she can ensnare her adversaries near and far,
    freezing their movements. Despite her fragile constitution, Azora's mastery over
    her craft grants her longevity in battles, as she maneuvers effortlessly amidst
    the chaos, her frost spells dictate the flow of combat. Her foes find themselves
    trapped in a chilling dance of survival, where every step could lead to their
    icy demise at the hands of this cunning spellcaster.
  passiveIcon: {fileID: 21300000, guid: 50eee26b30095d449a0391e278394e5a, type: 3}
  passiveName: Icy Chill
  passiveDescription: Whenever you take physical damage, slow the attacker's movement
    speed by <b>50%</b> for <b>5</b> seconds.
