import { Link } from "react-router-dom";
import { Button } from "./LandingPage";

export function Footer(props) {
  return (
    <div
      style={{
        position: "relative",
        zIndex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        width: "100%",
        padding: 20,
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            link="https://discord.gg/Rgy9wVQcqK"
            icon={<i className="fab fa-discord" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          <Button
            link="https://twitter.com/ringoftitansgg"
            icon={<i className="fab fa-twitter" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          <Button
            link="https://reddit.com/r/ringoftitans"
            icon={<i className="fab fa-reddit" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          <Button
            link="https://www.facebook.com/ringoftitans/"
            icon={<i className="fab fa-facebook" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          <Button
            link="https://www.youtube.com/@ringoftitans"
            icon={<i className="fab fa-youtube" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          <Button
            link="https://store.steampowered.com/app/1460550/Ring_of_Titans/"
            icon={<i className="fab fa-steam" />}
            target="_blank"
            style={{
              borderRadius: 16,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 8,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Link to="/creator">
          <Button
            icon={<i className="fas fa-video" />}
            style={{ borderRadius: 12, padding: "8px 12px" }}
          >
            CREATOR PORTAL
          </Button>
        </Link>
        <Link to="/link/discord">
          <Button
            icon={<i className="fab fa-discord" />}
            style={{ borderRadius: 12, padding: "8px 12px" }}
          >
            LINK YOUR DISCORD
          </Button>
        </Link>
        <Button
          icon={<i className="fas fa-newspaper" />}
          style={{ borderRadius: 12, padding: "8px 12px" }}
          link="https://impress.games/press-kit/mash/ring-of-titans"
        >
          PRESSKIT
        </Button>
      </div>
    </div>
  );
}
