import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc7",
  "Oct 23, 2024",
  `
- General
  - Improvements
    - Improved queue manager performance
    - Fixed an issue causing players to occasionally get put into a match after already being put into one.
    - Fixed an issue causing clicks to be eaten by floating combat text
    - Improved performance of authentication
    - Allowed for teams of 2 or 3 to queue for 3v3 (you can still solo queue too)
    - Made some adjustments to the MMR/rank system
    - The server now attempts to fix quest status if it gets out of sync on the client.
    - Made self titan frames show by default
    - Made combo point UI larger on nameplates
- Balance
  - Stormcaller
    - Lightning Storm now lasts 8 seconds (down from 10).
`
);

export default Notes;
