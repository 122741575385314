%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 85449c2ea301fc54091646f096d9e4dc, type: 3}
  m_Name: DarkDamage
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: 7f1c197a232a3d345aaa061a8840dedd,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 8300000, guid: 2c783095a3b2c8a4fa8cae902699c577, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 262
  castDelay: 0
  animationSpeed: 0.6
  animationDuration: 1.9
  skipCooldownOnChannel: 0
  spellName: Essence Burst
  description: Deal <b>15</b> damage to the target. Your first cast every <b>15</b>
    seconds will also <b>Stun</b> the target for <b>3</b> seconds.
  icon: {fileID: 2800000, guid: 1fdd2763b0fc2f449b24cae23b04b7a0, type: 3}
  usableBy: 08000000
  cooldown: 0
  castTime: 1.5
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}
  spellKey: 100006
  alwaysCastableSpell: 0
  luaScript: {fileID: 4900000, guid: a8650d35974c05c4dba85a436775c2cf, type: 3}
