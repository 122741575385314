%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: MysticalBrilliance
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 7489370104637420957, guid: ca4ffbb7fd64e3440b066bb5cbee0962,
    type: 3}
  castAuraScale: 0.4
  castAuraYOffset: 0.5
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 8300000, guid: 115326b0a1d301d408b2a59db74f0093, type: 3}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 22
  castDelay: 0
  animationSpeed: 0.8
  animationDuration: 1.6
  skipCooldownOnChannel: 0
  spellName: Mystical Brilliance
  description: "<b>Friendly Target:</b> heal the target for <b>24</b> health. \n\n<b>Enemy
    Target:</b> deal <b>9</b> damage to the target."
  icon: {fileID: 2800000, guid: c330824bb70e2c643a0339a300e8a697, type: 3}
  usableBy: 070000000d000000
  cooldown: 0
  castTime: 1.6
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 03c30f735d409064ea31b6b51d03dd6f, type: 2}
