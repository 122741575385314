import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc8",
  "Oct 26, 2024",
  `
- General
  - Improvements
    - Fixed many potential Authentication errors
    - Further performance improvements to the Authentication flow
    - Fixed a couple of tooltip typos/correctness issues
    - Fixed an issue where going to the main menu while on the end game screen caused all of the UI to disappear
    - Fixed a rare edge case where you'd still get pulled out of a match to go into another one
    - Spells set to mouseover no longer default to cast on yourself if there is no target under the mouse
- Balance
  - General
    - Health pools have been increased by ~10%
    - 50% healing reduction in duels vs players
`
);

export default Notes;
