%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerBlademasterWarrior
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2853453332119588009, guid: be1c54b2a09b3de46b747815e0b297bc,
    type: 3}
  heroClass: 11
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 220
  unitName: Tarcza
  unitSpec: Blademaster
  icon: {fileID: 2800000, guid: 6ca95c685a14328478a71db739ec37f5, type: 3}
  backgroundColor: {r: 0.24313724, g: 0.26777333, b: 0.65882355, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 95cbed828bf835049b3345c4a977845e, type: 3}
  type: BRUISER
  description: Tarcza is a formidable Blademaster whose ferocity is matched only
    by his unyielding determination. Known for his aggressive tactics and unwavering
    courage, Tarcza is a warrior who never retreats from a challenge. With each swing
    of his mighty blade, he embodies the relentless spirit of battle, driving forward
    with unbridled intensity. His opponents tremble at the sight of him, for they
    know that once Tarcza has set his sights on victory, nothing can stand in his
    way. In the arena of combat, Tarcza is a force to be reckoned with, a living
    testament to the undying resolve of a true warrior.
  passiveIcon: {fileID: 21300000, guid: d9c9a9083861bff4b97e856e530f7d53, type: 3}
  passiveName: Quick Recovery
  passiveDescription: Whenever you are <b>Stunned</b>, heal for <b>6</b> health every
    <b>2</b> seconds for <b>6</b> seconds. The effect does not stack.
