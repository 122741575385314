%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerPriest
  m_EditorClassIdentifier: 
  unitModel: {fileID: 2777422822122510713, guid: 892a4c77ee3e92c4496f6884a387772d,
    type: 3}
  heroClass: 2
  isHealer: 1
  level: 0
  attackSpeed: 1
  baseHP: 164
  unitName: Kanna
  unitSpec: Cleric
  icon: {fileID: 2800000, guid: e49abf2bcfe0d1a4381b6d5319affcf4, type: 3}
  backgroundColor: {r: 0.8588235, g: 0.8588235, b: 0.854902, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HEALER
  description: Kanna, a renowned Cleric in the Ring of Titans, commands the power
    of Holy magic to excel in combat. Specializing in absorption, she conjures impenetrable
    shields that deflect enemy attacks while bolstering her own strength. In the
    arena, Kanna is a beacon of balance, seamlessly transitioning between dealing
    damage to adversaries and providing vital support and healing to her allies.
    With her unwavering dedication and mastery of Holy magic, Kanna emerges as a
    formidable force, revered for her prowess and feared by her foes.
  passiveIcon: {fileID: 21300000, guid: 79acd8a805130f140b713a6f3e32257c, type: 3}
  passiveName: Prayer of Faith
  passiveDescription: Shield your target for <b>5%</b> of all healing you do. Does
    not stack with itself.
