import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc15",
  "Nov 9, 2024",
  `
- General
  - Improvements
    - Added new settings for nameplate overall scale and nameplate buff scale.
    - Added a 500 obol voucher to the shop.
  - Bug fixes
    - Fixed some issues with formatting in the translations.
    - Fixed an issue causing the mouseover hit box to be larger than intended for buffs.
    - Fixed some issues with the new skins.
    - Possibly fixed the T-Pose & nameplate bug that occurs very rarely.
    - Reduced the likelyhood of the issue where your client breaks after waiting for authentication for a while.
    - Fixed a bug where auto run would not correctly cancel in-progress casts/channels.
    - Fixed an issue where Sacred Cleansing a target in combat would not put the caster in combat.
- Balance
  - Soulstealer
    - Spirit Sector is now an instant cast spell (was: 1 sec cast).
  - Blademaster
    - The talent "Overpowered" now also increases the cooldown of Power Strike by 5 seconds.
    - "Inner Rage" now increases physical damage dealt by 35% for 5 seconds (changed from 50% for 4 seconds).
  - Naturalist & Stormcaller
    - Cultivation now stacks to 3 (up from 2).
  - Cleric
    - Added a new baseline spell called "Power Burn", which reads:
      - Deal 2 damage to the target and reduce the amount of outgoing healing & shielding they do by 2% per stack for 30 seconds. Stacks up to 30 times.
  - Assassin
    - Replaced the talent "Ambush" with a new talent called "Cheap Tactics" which reads:
      - Blow from the Shadows and Poison Blade increase all damage taken by the target by 20%.
  - Eradicator
    - Dark Portal is now baseline available for Eradicator.
    - The portal talent has been replaced with "Death Wish" (from Plaguebringer), which reads:
      - When Demon Skull hits a target, heal for 50 health.
  - Plaguebringer
    - The passive "Soul Essence" now stacks to 5, and provides 2% physical damage reduction per stack (changed from 1% stacking to 10).
    - The talent "Empowered Soul Essence" has been changed and now reads:
      - Consume all stacks of Soul Essence to reduce all physical damage taken by 6% per stack for 12 seconds.
      - The cooldown has also changed from 30 seconds to 45 seconds.
  - Crystalmancer
    - Crystals now last 10 seconds (up from 8 seconds).
    - The cast time of the 4th frost crystal is now 1.5 sec (down from 1.8 sec).
  - Lightbringer
    - Righteous Flash now has a 6 second cooldown (up from 5 seconds).
    - Mystical Brilliance now deals 9 damage when cast on an enemy target (down from 12).
  - Lightbringer & Smiter
    - Righteous Defense lasts 8 seconds now on allies (down from 10 sec).
      - It still lasts 10 seconds when cast on yourself.
`
);

export default Notes;
