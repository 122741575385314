import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc10",
  "Oct 29, 2024",
  `
- General
  - Improvements
    - Attempted to improve Swift Justice and Sweeping Revenge land logic to avoid being so far away upon landing
    - Fixed a race condition when entering lobby at the same time as a match found caused your character to appear in the lobby and the match at the same time.
    - Fixed an issue with hats on the Airion model
    - Powerup orbs spawn every 60 seconds now (up from 45) after their initial spawn (which is 2mins)
    - The matchmaking system now tries much harder to ensure solo queue teammates are closer in rank
    - The matchmaking system now attempts to avoid repeat matchups for a small period of time
    - Added a new Halloween theme item which will be in tomorrows shop
- Balance
  - General
    - Righteous Defense now makes the target honored for 90 seconds (up from 70 seconds)
    - Glacial Encapsulation now has a 90 second cooldown (up from 60 seconds)
    - Dark Suppression now has a 60 second cooldown (up from 45 seconds)
    - Soul Recovery now has a 40 second cooldown (up from 35 seconds)
    - Chain Breaker now has an 80 second cooldown (up from 70 seconds)
    - Enhanced Power now has a 45 second cooldown (down from 60 seconds)
    - Holy Fortitude now has a 90 second cooldown (up from 60 seconds)
    - Earth Shield now has a 90 second cooldown (up from 60 seconds)
    - Empowered Shields now has a 90 second cooldown (up from 60 seconds)
`
);

export default Notes;
