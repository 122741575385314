import balance from "url:../img/gif1.webm";
import ctf from "url:../img/gif2.webm";
import titans from "url:../img/gif5.webm";
import rankUp from "url:../img/gif3.webm";
import runes from "../img/runes.png?as=jpg";
import friends from "../img/friends.png?as=jpg";
import chat from "../img/chat.png?as=jpg";
import settings from "../img/settings.png?as=jpg";
import shop from "../img/shop.png?as=jpg";
import { DevComment } from "../DevComment.js";
import { MediaContent } from "../MediaContent.js";

const Notes = {
  label: "1.0 PATCH NOTES",
  date: "Oct 21, 2024",
  subtitle: (
    <>
      <div>
        1.0 will release on <strong>Oct 21, 2024</strong>
      </div>
      <DevComment>
        It's been over <strong>2 years</strong> since the last update. Thank you
        all for the support and for sticking around while waiting for an update,
        I hope this absolutely massive <strong>1.0 update</strong> will bring a
        new wave of enjoyment to Ring of Titans.
      </DevComment>
    </>
  ),
  children: [
    {
      label: "NEW FEATURES & UPDATES",
      children: [
        {
          label: <strong>New Titans</strong>,
          children: [
            {
              label: (
                <strong>
                  <a href="/titan/soulstealer">Soulstealer Kanna</a>
                </strong>
              ),
              children: [
                "An offensive, vulnerable healer that transfers health between herself and her teammates.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/crystalmancer">Crystalmancer Azora</a>
                </strong>
              ),
              children: [
                "A hybrid melee / caster that utilizes all elements to deal massive amounts of burst.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/bruiser">Bruiser Volen</a>
                </strong>
              ),
              children: [
                "A versatile and mobile melee damage dealer that can focus on bleeds, burst, or sustain depending on build choices.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/blademaster">Blademaster Tarcza</a>
                </strong>
              ),
              children: [
                "A two-handed sword wielding melee damage dealer with high mobility and sustain, but not many defensive options.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/smiter">Smiter Anthriel</a>
                </strong>
              ),
              children: [
                "A two-handed hammer wielding melee damage dealer with great support and high burst damage but few mobility tools.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/eradicator">Eradicator Malrah</a>
                </strong>
              ),
              children: [
                "A caster with consistent high burst damage and high control that casts a lot with not many defensive options.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/stormcaller">Stormcaller Airion</a>
                </strong>
              ),
              children: [
                "A hybrid caster & healer with huge burst potential which can be difficult to set up.",
              ],
            },
            {
              label: (
                <strong>
                  <a href="/titan/marksman">Marksman Orin</a>
                </strong>
              ),
              children: [
                "A ranged physical damage dealer which is less mobile than Hunter Orin, but has higher damage with the tradeoff of having to stand still to cast more.",
              ],
            },
          ],
          after: (
            <MediaContent
              media={titans}
              subtitle={
                <>
                  The new <strong>Smiter Anthriel</strong> Titan winning a duel
                  against <strong>Stormcaller Airion</strong>.
                </>
              }
            />
          ),
        },
        {
          label: <strong>Balance Pass</strong>,
          children: [
            "All existing Titans have had a balance pass, adjusting damage numbers, spell availability, and Talents.",
            {
              label:
                "All Titans now have a thematic passive intended to increase skill cap without increasing the barrier to entry.",
              children: [
                <>
                  You can learn more about each Titan's passive ability{" "}
                  <a href="/titans">here</a>.
                </>,
              ],
            },
            <>
              All Titans now have <strong>Chain Breaker</strong> out of the box,
              which is no longer a general Talent.
            </>,
            {
              label: "4 new General Talents have been introduced:",
              children: [
                <>
                  <strong>Toughness</strong>: Passive 20% reduction in Stun
                  duration.
                </>,
                <>
                  <strong>Resolve</strong>: Passive 20% reduction in
                  Incapacitate duration.
                </>,
                <>
                  <strong>Warmth</strong>: Passive 20% reduction in Snare and
                  Slow duration.
                </>,
                <>
                  <strong>Enhanced Resilience:</strong> A new spell which grants
                  20% damage reduction for 10 seconds.
                </>,
              ],
            },
            "You'll now choose 3 out of the 9 available general Talents (instead of 2 out of 6).",
            "The diminishing returns timer to reset crowd control durations has been increased to 22 seconds (up from 18 seconds).",
            "The combat timer has been increased to 6 seconds (up from 5 seconds).",
            "There are too many Titan balance changes to list each one out here, so you'll have to explore in game to learn of all of the changes.",
          ],
          after: (
            <>
              <DevComment>
                Overall, the goal for 1.0 is to make the game faster paced, as
                well as improving some poorly designed Talents. Damage and
                healing is higher, health pools are lower. The new Talents to
                reduce crowd control durations and a longer DR timer make it so
                you spend less time in crowd control and more time playing.
              </DevComment>
              <MediaContent
                media={balance}
                subtitle={<>Existing Titans fighting with higher damage.</>}
              />
            </>
          ),
        },
        {
          label: <strong>Capture the Flag</strong>,
          children: [
            <>
              A new 5v5 Capture the Flag (CTF) mode has been introduced,
              alongside a brand new map for it.
            </>,
            <>
              In this mode, there are two teams of 5 players attempting to
              capture the flag from the other team's dock.
            </>,
            <>
              There's a map UI built just for this mode where you can see the
              locations of other players and the flags.
            </>,
            <>If you die, you'll respawn after 20 seconds.</>,
            <>
              To capture the enemy team's flag, you need to bring it from the
              enemy team's dock to your own dock while the enemy team does not
              hold your flag.
            </>,
            <>
              To return your flag back to your base after it has been captured,
              you simply kill the Titan who captured the flag.
            </>,
            <>
              The first team to capture 3 flags wins. If no team has captured 3
              flags by 15 minutes, the team with more captures will win. If both
              teams have an equal number of captures at 15 minutes, it will be a
              draw.
            </>,
          ],
          after: (
            <MediaContent
              media={ctf}
              subtitle={<>Gameplay on the new CTF mode.</>}
            />
          ),
        },
        {
          label: <strong>Powerups</strong>,
          children: [
            <>
              <strong>Powerups</strong> have been introduced, which are orbs
              that provide bonuses to the team who picks them up.
            </>,
            {
              label: "All Powerups:",
              children: [
                "Look like a glowing orb.",
                "Can be picked up by walking near them.",
                "Spawn on top of small raised platforms on each map.",
                "Apply to your entire team (or the enemy team) when picked up.",
                "Begin spawning after 2 minutes of play time (not counting prep time).",
                "Spawn every 45 seconds after the first spawn.",
                "Last for the remainder of the match after being picked up.",
                "Stack infinitely.",
              ],
            },
            {
              label: "There are currently 3 Powerups:",
              children: [
                "10% Damage Bonus (purple, always spawns in the center).",
                "10% Damage Reduction Bonus (green, spawns in a corner).",
                "10% Healing Reduction Debuff (applies to the opposite team) (yellow, spawns in a corner).",
              ],
            },
            "There is also new UI that you can use to track the Powerup timers and pickups by each team.",
            ,
          ],
          after: (
            <DevComment>
              The intent here is to add a more strategic element to the game in
              addition to the micro gameplay skill already involved. The new
              powerups also replace the old "Dampening" effect that would
              automatically apply throughout the match to ensure matches
              eventually end.
            </DevComment>
          ),
        },
        {
          label: <strong>Runes & Titan Points</strong>,
          children: [
            {
              label: (
                <>
                  There's now a new currency called <b>Titan Points</b>, which
                  you can use to buy <b>Runes</b>.
                </>
              ),
            },
            <>
              <b>Runes</b> provide small stat bonuses (such as dealing 1-3% more
              physical damage), and you can use up to 3 Runes at once.
            </>,
            <>
              Each <b>Rune</b> has 3 tiers, where you must buy the previous tier
              before unlocking the next. You are able to stack multiple Runes of
              the same type of different tiers.
            </>,
            {
              label: (
                <>
                  You'll earn Titan Points by playing arenas or the new CTF mode
                  against players. You'll be able to earn up to 7000 Titan
                  Points each week.
                </>
              ),
            },
            <>
              After you've purchased all of the Runes you wish to acquire each
              Season, you can exchange Titan Points for Obols in the Shop. Titan
              Points will reset each Season, but Obols will not.
            </>,
          ],
          after: (
            <>
              <DevComment>
                One of the original goals of Ring of Titans was to ensure there
                was no grind required to play. However, it's always nice to have
                something to work towards to get a sense of progression as you
                play. So the goal here is to find a good balance between giving
                a sense of progression while still ensuring the advantage is not
                too high. In addition, having Runes adds a theorycrafting
                metagame around which Runes to buy first and determining which
                are best for which Titans in in which scenarios and matchups,
                which can be fun for players into that part of the game.
              </DevComment>
              <MediaContent
                media={runes}
                subtitle={"The new Rune selection UI"}
              />
            </>
          ),
        },
        {
          label: <strong>Social Features</strong>,
          children: [
            "You can now add friends directly in game. Just right-click on any Titan frame or chat message to add a friend.",
            "The Steam-based party system has been replaced entirely with an in-game system. This makes it easy to find teammates to play with in game.",
            "You can now send whispers in the game directly to other players.",
            "You can now challenge players, add friends, or invite to party by right-clicking chat messages (in addition to Titan frames).",
            "Inappropriate chat messages can now be reported directly in game by right-clicking the message.",
            'As also noted below in "Chat Improvements", there are now chat channels that can be organized into tabs, such as global chat, LFG, team, party, etc.',
          ],
          after: (
            <MediaContent
              media={friends}
              subtitle={"UI to add a new friend or share your friend ID."}
            />
          ),
        },
        {
          label: <strong>Chat Improvements</strong>,
          children: [
            "Introduced chat channels to keep chat organized and allow chatting within a zone, team, party, etc.",
            {
              label: (
                <>
                  In addition, you can now create up to 8 chat tabs with custom
                  names and choose which channels display in each tab.
                </>
              ),
            },
            {
              label: (
                <>
                  There's additionally a new chat channel for the combat log,
                  showing all damage dealt and the source. This also shows the
                  raw amount, such as `12.4` which was previously inaccessible.
                </>
              ),
            },
            {
              label: (
                <>
                  Changed the Chat UI to use a font that is easier to read, and
                  increased the font size.
                </>
              ),
            },
            {
              label: (
                <>
                  All chat messages now contain a timestamp of when it was sent.
                </>
              ),
            },
            {
              label: (
                <>
                  Chat no longer scrolls to the bottom if you're not already
                  scrolled to the bottom, allowing you to read messages in the
                  middle without constantly being scrolled back down.
                </>
              ),
            },
            {
              label: (
                <>
                  Chat performance has been improved significantly. The max
                  number of messages shown has been increased to 1000.
                </>
              ),
            },
          ],
          after: (
            <MediaContent
              media={chat}
              subtitle={
                "New chat UI including chat tabs and a combat log channel."
              }
            />
          ),
        },
        {
          label: <strong>Settings Improvements</strong>,
          children: [
            {
              label: (
                <>A ton of new settings have been introduced, including:</>
              ),
              children: [
                "A setting to choose which cooldowns are tracked on the global enemy cooldown tracker UI.",
                "Settings to choose which cooldowns are tracked for each individual cooldown tracker on Titan Frames.",
                "Settings to choose which buffs show on each Titan Frame/Nameplate.",
                "Settings to enable/disable UI tinting when Titans are out of a chosen range (e.g. fade out when over 40 yards away).",
                "A hold-to-cast setting which allows you to hold a key down to continually cast it instead of having to spam a keybind over and over.",
                "A setting to disable auto spell swapping, which allows you to separately keybind buff cancel spells (e.g. Cancel Glacial Encapsulation).",
                "A setting to fade out your own nameplate while you have a target.",
                "Settings to hide player names, and disable chat bubbles (useful for streamers / content creators).",
                "And more...",
              ],
            },
            {
              label: "UI Edit Mode",
              children: [
                {
                  label: (
                    <>
                      There's a new setting to enable moving and resizing
                      frames, which pops up a UI allowing you to move and resize
                      all frames at once. This will make it much easier to set
                      up your UI without having to jump into bot games to get
                      all of the frames to show up.
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      In addition, UI frames that were previously not movable
                      can now be moved and resized, such as the self cast bar,
                      and the crowd control overlay.
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      The new edit mode also snaps frames to a grid, as you move
                      them allowing the creation of clean UIs.
                    </>
                  ),
                },
              ],
            },
            "You can now import & export UIs, so it's easy to share your UI layout with your friends.",
            "In addition, the UI & keybinding settings sections have been reorganized to be much easier to use.",
          ],
          after: (
            <MediaContent
              media={settings}
              subtitle={
                "Explore a plethora of well organized settings in the updated settings UI."
              }
            />
          ),
        },
        {
          label: <strong>Visual Improvements</strong>,
          children: [
            "All Titan models have been updated with brand new high quality 3D models.",
            "The spell VFX for every single existing spell and all animations have been redone to be far more polished and satisfying.",
            "Shading, fog, and overall graphical cohesion have all been vastly improved.",
            "Every map, including the lobby and each arena has been improved visually.",
            "The UI now has satisfying animations and sound effects.",
            {
              label: "Loads of additional visual improvements:",
              children: [
                "Added a sun, which rotates, gets bigger near the horizon and is synced with the other players in the lobby.",
                "Grass now emits shadows and has improved shading.",
                "Lighting has been improved in every map.",
                "The feet of Titan models now matches the ground (i.e. it won't clip through the ground).",
                "Titan models now rotate to match the terrain while moving.",
                "God ray VFX has been added.",
                "Fixed several visual issues and bugs with animation transitions.",
                "Titan models now properly close their hands around their weapons.",
                "Your Titan's head now turns to face the same direction that the camera is facing.",
                "Added a flash visual effect when taking damage or being healed.",
                "Transitions between running, sprinting, and walking are now done smoothly.",
                "Improved floating combat text animation and visual artifacts (such as showing too far away, in the wrong location, and off-screen).",
                "Visual effects no longer instantly disappear, they have clean fade out animations.",
                "Visual effects attached to Titans now properly align while mounted.",
                "The mount is now centered while mounted instead of being offset.",
                "Turning animations have been significantly tweaked.",
                "Added dirt particles wherever a footstep occurs.",
                "Updated the cursor image, which is also dynamic (such as changing to a sword while hovering over an enemy Titan).",
              ],
            },
          ],
        },
        {
          label: <strong>Lobby Activities</strong>,
          children: [
            "You can now duel other Titans right in the lobby.",
            "There's a new jump quest which is far more challenging.",
            "There's a daily Prize Wheel you can spin to win Obols.",
            "The Town in the lobby has been completed, with houses that can be entered and explored.",
            "You can now sit on the chairs in the lobby and hang out with your friends.",
            "Local chat (another new feature) shows speech bubbles above your head.",
            "There are now signposts in the lobby to help navigate to various locations/activities.",
          ],
        },
        {
          label: <strong>Shop Updates</strong>,
          children: [
            "Added a Daily Shop section, which rotates daily with new items available.",
            "The set of items in the Daily Shop is random and is generated each day.",
            {
              label: "There are two new types of cosmetics: Weapons & Emotes",
              children: [
                "Multiple weapons can be equipped at a time (e.g. a one-hander and a shield).",
                "Up to 8 emotes can be equipped at once.",
                "There's a new emote action (default keybind: H) which opens an emote wheel UI, allowing you to choose one of your equipped emotes to use.",
              ],
            },
            "You can now preview items from the shop before purchasing them.",
            "The shop now shows items that you already own, greyed out with a tag.",
            {
              label:
                "You can now purchase additional Obols in game, which also helps support the game's future development.",
              children: [
                "Optionally, enter your favorite creator's Creator Code to help support them too and receive 5% more Obols per purchase.",
              ],
            },
          ],
          after: (
            <MediaContent
              media={shop}
              subtitle={
                <>
                  The new rotating <strong>Daily Shop</strong> tab.
                </>
              }
            />
          ),
        },
        {
          label: <strong>New Rank System</strong>,
          children: [
            "The MMR-based rating system has been replaced with a Rank system.",
            "You need to play 6 placement matches before you get a rank.",
            "Your rank is still separate for each bracket (e.g. 2v2, 3v3) and Titan.",
            "There are 7 ranks: Bronze, Silver, Gold, Platinum, Diamond, Titan, and Grand Titan.",
            "Within each rank (except Grand Titan), there are 3 tiers (e.g. Bronze I, Bronze II, Bronze III), where III is the highest.",
            "Within each rank and tier, there are 3 divisions (e.g. Bronze I Div I), where Division III is the highest.",
          ],
          after: (
            <>
              <DevComment>
                While it varies slightly depending on your rank and your
                opponent, the aim is for 2-3 wins in a row to get you up to the
                next division.
              </DevComment>
              <MediaContent
                media={rankUp}
                subtitle={
                  <>
                    Showcasing the <strong>Rank Up</strong> animation after
                    reaching <strong>Grand Titan</strong>.
                  </>
                }
              />
            </>
          ),
        },
        {
          label: <strong>New Onboarding / Tutorial</strong>,
          children: [
            "The new tutorial has 3 options: beginner, intermediate, or skip. The tutorial now provides rewards as you progress through it, including Obols and Account XP.",
            "If you choose to skip the tutorial, you can still start it later via the settings menu. However, if you start in intermediate mode, you won't be able to go back to beginner mode later and will lose out on the extra rewards.",
            {
              label:
                "The bot experience has also been improved for new players:",
              children: [
                "Lower ranked bots now use most spells other than crowd control and major defensive spells.",
                "You always start at Bronze I vs bots, and gain roughly one division each win.",
                "Bots now get a debuff at lower ranks that reduces their damage/healing output and increases the amount of damage they take.",
                "At higher ranks, bots get a buff that increases their damage/healing and reduces the amount of damage they take.",
                "The higher the rank, the stronger they will get, with no limit.",
                "This allows you to use bots as a great way to practice no matter your skill level, as you'll eventually reach a point where they become challenging.",
                "There's also a quest chain giving rewards for winning against bots all the way up to Grand Titan rank.",
                "In addition, the bot logic has been improved, and more bots have been implemented to add more variety to matches.",
              ],
            },
            "All tooltips have had their readability and English improved including better spacing and bolding key words to be easier to understand.",
            "The camera is now always behind your Titan by default (can be disabled in settings). This makes it easier for new players to track where they are facing",
            "You can now turn your Titan via keybind actions (default A and D), which may be more intuitive for new players compared to right click dragging to adjust your facing direction.",
          ],
        },
        {
          label: <strong>Quests & Daily Quests</strong>,
          children: [
            "Added a new quest system, used for both the tutorial and daily quests.",
            "You get one daily quest every day, and can hold a maximum of 3.",
            "For example, if you complete all of your daily quests and log in a week later, you'll have 3 more to do.",
            "If you complete only one daily quest and log in the next day, a new one will take its place.",
            "Every daily quest rewards 25 Obols and 2000 Account XP.",
            "You can also reroll a daily quest once per day if you get one you don't like.",
            "In addition, you'll get a quest every time your daily wheel spin is available, to remind you and give a small additional reward.",
          ],
        },
        {
          label: <strong>Matchmaking Heuristics</strong>,
          children: [
            'Previously, matchmaking would always try to find the "best" matches every 10 seconds',
            {
              label:
                "Now we use a heuristic to determine if a matchup is acceptable, taking into account factors such as:",
              children: [
                "Time waiting in queue",
                "MMR difference between teams",
                "Number of players online / in queue",
              ],
            },
            "It's still expected to find matches quickly (<30 sec), especially when fewer players are online.",
            ,
          ],
          after: (
            <DevComment>
              The overall goal here is to ensure higher quality matchups, but a
              tradeoff is made to ensure matches occur quickly when less players
              are available.
            </DevComment>
          ),
        },
      ],
    },
    {
      label: "FIXES & IMPROVEMENTS",
      children: [
        {
          label: "Bug Fixes",
          children: [
            "Fixed an issue where you could not hit the bots in the lobby with area of effect spells.",
            "Fixed an issue causing player names to overflow to multiple lines on nameplates.",
            "Fixed an issue causing HP text to overflow to multiple lines on nameplates.",
            "Fixed an issue where VFX spawned in would appear for one frame at 0,0,0 before moving to the correct location.",
            "Fixed an issue preventing persistent VFX spawned from spells (such as dark portal) not being destroyed when leaving matches or zones.",
            "Fixed an issue where the cast bar UI would block the mouse.",
            "Fixed an issue allowing you to chain certain active spells (like Empowered Shields) when recasting it near the end of the duration.",
            "Fixed an issue causing you to lose your Combo Points when using a spell that the server rejected.",
            "Fixed an issue causing Titans to sometimes slightly be mispositioned immediately after using a movement effect like Swift Justice.",
            "Fixed an issue causing tooltips to sometimes appear offscreen. They now attempt to position themselves to an area that will be on screen.",
            "Fixed an issue preventing casting spells on other players in the lobby.",
            "Fixed an issue preventing buff VFX (e.g. shield VFX) from showing on existing Titans when entering a new zone.",
            "Fixed a visual issue where the queue status indicator would sometimes show the wrong brackets as being queued for.",
            "Fixed an issue where tooltips could be stuck being visible permanently when changing zones.",
            "Fixed an issue where going to the main menu while spectating a match using freecam caused the camera to break permenantly.",
            "Fixed an issue where your Obol count shown on the main menu wouldn't update immediately after each match.",
            "Fixed an issue where your Obol amount shown on the main menu wouldn't update after making a purchase in the shop.",
            "Fixed an issue where dragging an icon off an action bar slot and then back onto it broke the cooldown spinner and other tint indicators.",
            "Fixed an issue where the Free Fly camera in spectate or replay mode would not reset properly.",
            "Fixed an issue which would occasionally cause chat messages to be cut off.",
            "Fixed an issue where the experience bar on the main menu would sometimes not update after a match completed.",
            "Fixed an issue preventing players in a party from queuing for 3v3 vs bots.",
            'Fixed several issues causing players to get stuck on "Authenticating" or "Waiting for all players".',
            "Fixed an issue where you could get stuck in queue and be unable to leave it.",
            "Fixed a bug causing teams to leave queue unexpectedly, and cause their queue status to be out of sync.",
            "Fixed an issue where you could open the spellbook on the main menu.",
            "Fixed an issue where if you found a match to join right as you entered the lobby, you wouldn't be able to join the match.",
          ],
        },
        {
          label: "Improvements",
          children: [
            "The client now connects to up to two servers: a master server and a world server. The master server handles things like the queue and chat, and the world server handles the rest.",
            "The client can reconnect to a server if it gets unexpectedly disconnected, including the ability to rejoin an in-progress match.",
            "The client can now cancel a connection attempt if it is taking too long to connect to a server or too long to start a match.",
            'The "selection circle" that shows below Titans that are targeted or focused now always follows the ground.',
            "Adjusted the ordering of UI elements to make more sense, such as putting nameplates below UI windows.",
            "Updated the spell book UI to be organized into sections and include the passive for each Titan.",
            "The default zoom value has been decreased so your camera is closer to your Titan.",
            "The max zoom value has been decreased so you can't zoom out quite as far.",
            "Improved netcode implementation, including better client-side prediction, rollback and latency handling.",
            "Improved the method used to create rounded corners on the UI to be higher quality.",
            "Added dust, wind and other various particle effects around the camera/Titan.",
            "The primary cursor is now hidden when in AoE cursor mode (when choosing where to cast a spell).",
            "All spells for a Titan are now shown on the Titan Confirmation screen when changing Titans (used to be a max of 12 spells shown).",
            'Cast bars now say "Success" or "Interrupted" when a cast completes, so it is clear what the result of the cast was.',
            "Your camera and Titan now face reasonable directions whenever entering a new zone (lobby, arena, etc).",
            "The Titan nameplate UI height is now dynamic depending on the height of the Titan model.",
            "You now have separate spells to cancel active buffs (for example, Glacial Encapsulation and Cancel Glacial Encapsulation).",
            "In settings, you can choose to disable auto swapping spells, so that pressing the same button won't cancel the effect and you can set a separate button to cancel.",
            "Titan Nameplate draw distance is now much larger while in a match, and smaller in the lobby.",
            "Added a ton of system messages to confirm when actions have been taken (e.g. requesting custom games, inviting to party, etc) or errors occur.",
            "The UI for nameplates and Titan frames has been redone to be more consistent, better aligned and more compact.",
            "Healers can no longer queue for duels and bot duels will never place you against a healer Titan. However, healers can still challenge players to custom match 1v1s, or duel in the lobby.",
            "The game version now shows on the main menu.",
            "Titan Frames and Nameplates now change their icon for major positive cooldowns, such as defensives like Empowered Shields.",
            "Titan Frames and Nameplates now show text (e.g. 5.4) to indicate the remaining duration of major effects (such as crowd control or defensive CDs) when active.",
            "There's now more shuffling variance in matchmaking, helping to prevent continually getting the same matchups over and over.",
            'Added new "cinematics" when entering and concluding a match, showcasing the players involved.',
            "There's now a confirm dialog that shows up when you attempt to go to the main menu while in a match.",
            "Added a button to copy keybindings from another Titan (in keybinding settings at the top).",
            "All keybindings are now separate for each Titan. Previously only the action slot keybindings were separate, but now you can also separate out other keybinds like targeting ones.",
            "Channeled spells now start at 100% and count down to 0% on the cast bar (previously it went from 0% -> 100% like regular casts).",
            "The mature language filter has been improved.",
            "Increased the font size on tooltips.",
            "Absorption and shielding provided now counts as healing on the end of match screen.",
            {
              label:
                "All UI locations where text is shown to indicate a countdown is now shared.",
              children: [
                "This means that all cooldown/duration text has decimals when less than 10 seconds remain.",
                "In addition, all cooldown/duration text updates the color as it gets closer to zero.",
              ],
            },
            "Added a new animation that plays when you suddenly stop moving.",
            "Some spells now snapshot facing direction at the end of the animation, and some at the beginning (e.g. Icy Shards snapshots at the start, and Dash at the end).",
            "The amount of movement made by the cloth physics on cloaks has been reduced to prevent cloaks from clipping through Titan models as much as it did.",
            "Made it so that you should be able to successfully auth even if Steam goes down while playing. No more getting locked out during Tuesday Steam maintenance.",
            "Added the option to view Combo Points for enemy Titans, making it easy to track if desired.",
            "You can now set up to 2 keybinds per action and action slot.",
            {
              label: "Added more bot implementations:",
              children: [
                "Lightbringer Anthriel",
                "Hunter Orin",
                "Eradicator Malrah",
                "Soulstealer Kanna",
                "Smiter Anthriel",
              ],
            },
            'Updated replays, spectate list, and current status on Steam include the Titan "Spec" (e.g. Eradicator) in addition to their name.',
            "Made it so that hide UI cannot hide the escape menu and the settings page, so that if you accidentally bind left mouse to hide UI, you can still fix it.",
            "Equipped Titles are now bolded instead of being a different color, making it easier to see them on certain nameplates.",
            "Changing your selected customization (e.g. hat, cloak) now happens much faster.",
            "Removed the self-cast and focus-cast modifiers. These were confusing and not implemented well - the workaround is to keybind your focus and self casts separately.",
            "Added a keybind to target the first enemy. This differs from the existing keybind to target next enemy because it does not cycle through the list.",
            "Improved the logic for targeting the first/nearest enemy.",
            "Renamed arena1/2/3 to enemy1/2/3 and party1/2/3 to team1/2/3 as it is easier to understand, and not everything is an arena anymore since we have CTF now.",
            'Added a "highlight" effect on action slots where the spell has a special condition met, such as Power Strike dealing more damage after Devastating Strike has been cast. This can be disabled in General Settings.',
            "Added a setting to fade out your own nameplate when you have a target, making it easier to see your target's nameplate.",
            "Spells you don't currently have (like spells from Talents, if you haven't selected that talent) are now grayed out.",
            "Cooldowns above 60 seconds now correctly show on the cooldown tracker UI.",
            "Added a total online player count in the top right of the UI.",
            "Floating damage and healing text now no longer shows up if you are too far from it.",
            "Minor reorganization of the main menu UI, putting the chat in the bottom right by default.",
            "Cosmetic choices (hats, cloaks, etc) now sync with your party on the main menu when you change them.",
            "Titles are now shown on the main menu.",
            "Cosmetic choices are now saved per-Titan, so you can now, for example, choose a different hat for every Titan.",
            "Several directional spells now aim towards your Titan's movement direction, so if you hold down a movement key midair it will adjust the direction of the spell.",
            "You can now left-click (in addition to right-clicking) on a talent you already have to unlearn it.",
            "Added some tooltips on the main menu nav items and queue menu items.",
            'Added a setting for "Hold to Cast", which allows you to hold a keybind to continuously cast that skill whenever it can be cast.',
            "All matches now give 30 seconds for preparation (up from 20), including the intro cinematic.",
            'When you damage targets that are immune to damage or buffs, it now says "IMMUNE" instead of there being no floating text at all.',
            "You can now sit in most of the chairs/benches/stools/etc in the Lobby.",
            "Bots now switch target if their current target becomes immune.",
            "Renamed M0 -> M1, M1 -> M2, etc. Left click should be M1 now, instead of M0.",
            'Added "Tips" that are sent in chat every 2 minutes, helping to explain some concepts. If you have ideas for new tips, please let me know!',
            "Added a credits tab in settings.",
            "Removed a bunch of UI scale/position settings since the fullscreen UI edit mode replaces those options.",
            "Added a convenient button after a match ends to immediately requeue for the same modes you had previously selected.",
            "Removed the setting to toggle cooldowns on Nameplates, which did nothing anyway since Nameplates cannot show cooldowns.",
            "Added several windows to the list of windows that automatically close when you press escape.",
            'Graphics settings has been simplified to just "low", "medium" and "high" options.',
            "Obol, Titan Point, and EXP UI has been improved to take up less space and be easier to read.",
            "New spells received (e.g. during the Tutorial, or when learning new Talents) are automatically placed on the action bars now.",
            "Diminishing Return Timers and the Cooldown Tracker on Titan Frames are now disabled by default.",
            "Added quick access buttons (buttons at the top of the screen) for the escape menu and settings.",
            "The max amount of Obols you can get for playing matches each day has been updated to 25 (you can get another 25 from a daily quest, plus up to 500 more from the daily wheel spin).",
            'The "Interrupt Bar" UI has been renamed to "Enemy CD Tracker", as you can now choose which enemy cooldowns to track in UI Settings.',
            "Titan Frames for far away Titans are now tinted darker. You can choose how far away to tint (or disable it) in UI Settings.",
            "Two of the same buff can now apply to a single Titan, if the source was from different Titans. For example, Titan A and Titan B can both cast Cultivate on Titan C.",
          ],
        },
      ],
    },
  ],
};

export default Notes;
