import styles from "@radix-ui/themes/styles.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Theme } from "@radix-ui/themes";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

styles;

ReactDOM.render(
  <React.StrictMode>
    <Theme appearance="dark" accentColor="green">
      <App />
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);
