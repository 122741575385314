import { useState } from "react";

export function MediaContent({ media, subtitle }) {
  const [expanded, setExpanded] = useState(false);

  const mediaEle = media.includes("webm") ? (
    <video
      style={{
        width: "100%",
        borderRadius: 8,
        margin: "8px 0",
        cursor: "pointer",
      }}
      onClick={() => setExpanded((old) => !old)}
      loop
      autoPlay
      muted
    >
      <source src={media} type="video/webm" />
    </video>
  ) : (
    <img
      src={media}
      style={{
        width: "100%",
        borderRadius: 8,
        margin: "8px 0",
        cursor: "pointer",
      }}
      onClick={() => setExpanded((old) => !old)}
    />
  );

  const expandedEle = expanded ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: "rgba(0, 0, 0, 0.95)",
      }}
      onClick={() => setExpanded(false)}
    >
      <div style={{ width: "80%" }}>{mediaEle}</div>
      <div style={{ fontSize: 14, fontStyle: "italic" }}>{subtitle}</div>
    </div>
  ) : null;

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", maxWidth: 616 }}>{mediaEle}</div>
        <div style={{ fontSize: 14, fontStyle: "italic" }}>{subtitle}</div>
      </div>
      {expandedEle}
    </>
  );
}
