import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc13",
  "Nov 4, 2024",
  `
- General
  - Improvements
    - The CC overlay always picks the longest CC to show now.
    - When Titan frame icons are replaced with important buffs, CC now has priority over other effects.
    - The mature language filter now works on player names in addition to chat messages.
    - Updated the action bar context menu (for selecting target, focus, etc) to be dark to be easier to see.
    - The action bar context menu now closes when you go to the main menu if you don't close it yourself.
    - Buff tooltips now show whether that buff can be removed by Sacred Cleansing.
    - Added a few new cosmetics to the pool.
    - You can now purchase Obol vouchers  in the misc section of the shop. This will give you a one-time code to redeem that amount of Obols which you can share with friends, do giveaways, run tournaments, or whatever you'd like.
    - The Diminishing Return (DR) count now shows on the DR tracker icons.
    - The spectator camera can now zoom out further while locked on a player.
  - Fixes
    - Improved collision issues causing players to fall out of bounds of arena occasionally.
    - Fixed a bug causing the Sharp Ice projectiles to be permanently floating sometimes.
    - Fixed a few tooltip typos and incorrect information.
    - The self Titan frame can now be properly aligned with the target/focus frame. You will have to adjust your UI layout if you've moved it.
- Balance
  - General
    - A new stack of dampening is now added once every 3 seconds (down from 6 seconds) after starting.
    - Fixed a bug with Bandage causing multiple heals on the first tick sometimes.
    - Increased animation speeds for several key spells (Inner Rage, Shadow Blink, Frost Portal, Shadow Demons).
  - Soulstealer
    - Essence Burst is now a 1.5 second cast (up from 1.3 seconds)
    - The Soothing Souls talent now only restores 50% of health taken (down from 75%).
    - Ethereal Binding now lasts 7 seconds (down from 9).
    - Fixed a bug causing the Soul Capture talent to unintentionally reduce the cooldown of Soul Recovery by 18 seconds instead of 8.
  - Crystalmancer
    - Added a range to Crystal Blast (40 yards) and a LoS check.
  - Blademaster
    - Inner Rage is no longer on GCD, and now increases all physical damage dealt by 50% for 4 seconds.
  - Assassin
    - Fixed an issue with Smoke Screen allowing enemy teammates to heal into it.
  - Naturalist
    - Slam no longer requires Lightning Form to cast.
`
);

export default Notes;
