import { AspectRatio, Card, Flex, Inset, Text } from "@radix-ui/themes";
import { PageLayout } from "./PageLayout";
import Countdown from "react-countdown";
import { useMeasure } from "react-use";

export const Button = ({
  icon,
  afterIcon,
  spinIcon,
  children,
  link,
  onClick,
  target,
  style,
}) => {
  const targetOpt = {};
  if (target) {
    targetOpt.target = target;
  }

  const extraIconStyles = spinIcon
    ? {
        animation: "spin 800ms linear infinite",
      }
    : {};

  return (
    <a
      href={link}
      onClick={onClick}
      {...targetOpt}
      style={{ textDecoration: "none" }}
    >
      <div
        className="btn"
        style={{
          display: "flex",
          gap: 8,
          padding: 16,
          cursor: "pointer",
          textShadow: "0 0 5px black",
          color: "white",
          lineHeight: 1,
          ...style,
        }}
      >
        {icon && (
          <div style={{ display: "inline-block", ...extraIconStyles }}>
            {icon}
          </div>
        )}
        {children}
        {afterIcon && (
          <div style={{ display: "inline-block", ...extraIconStyles }}>
            {afterIcon}
          </div>
        )}
      </div>
    </a>
  );
};

const NumField = ({ amt, label }) => {
  if (amt < 10) {
    amt = "0" + amt;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{amt}</div>
      <div style={{ fontSize: 24, fontWeight: 100 }}>{label}</div>
    </div>
  );
};

const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  props,
}) => {
  if (completed) {
    return props.children;
  } else {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          gap: 40,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <NumField label="DAYS" amt={days} />
        <NumField label="HOURS" amt={hours} />
        <NumField label="MINUTES" amt={minutes} />
        <NumField label="SECONDS" amt={seconds} />
      </div>
    );
  }
};

function LandingPage() {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  const trailerWidth = width;
  const trailerHeight = trailerWidth / (16 / 9);

  return (
    <PageLayout>
      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: 20,
          width: "100%",
        }}
      >
        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 20,
            }}
          >
            <div style={{ fontSize: 40, lineHeight: 1 }}>1.0 RELEASES IN</div>
            <div
              style={{
                fontSize: 66,
                lineHeight: 1,
                fontWeight: 800,
                fontFamily: '"Roboto Mono", monospace',
              }}
            >
              <Countdown date={1729533600000} renderer={countdownRenderer}>
                1.0 IS RELEASED!
              </Countdown>
            </div>
          </div>
        </Card>

        <Flex gap="2" align="start">
          <Card
            style={{
              width: "100%",
              flexShrink: 0,
              maxWidth: trailerWidth,
            }}
          >
            <Inset clip="padding-box" side="top" pb="current">
              <iframe
                style={{
                  width: "100%",
                  height: trailerHeight,
                }}
                src="https://www.youtube.com/embed/rHERYwv1tao?autoplay=1&loop=1&modestbranding=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Inset>
            <Text
              as="p"
              size="3"
              style={{ fontFamily: '"Montserrat", sans-serif' }}
            >
              <strong>Ring of Titans</strong> is a modern take on the
              small-scale fast-paced arena genre. Pick a Titan and queue up to
              play 1v1, 2v2, or 3v3, the first team to eliminate everyone from
              the other team wins. No grinding, no pay to win, no RNG. Just
              queue and play.
            </Text>
          </Card>
        </Flex>
      </div>
    </PageLayout>
  );
}

export default LandingPage;
