%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerEradicatorWarlock
  m_EditorClassIdentifier: 
  unitModel: {fileID: 5925594905447184141, guid: 1d2827baba4a3ca4190c404cfb6a598a,
    type: 3}
  heroClass: 12
  isHealer: 0
  level: 0
  attackSpeed: 1
  baseHP: 160
  unitName: Malrah
  unitSpec: Eradicator
  icon: {fileID: 2800000, guid: 6a1e0d2d7dc596e40beeb25d5d5d18ee, type: 3}
  backgroundColor: {r: 0.4716981, g: 0.113029525, b: 0.2992613, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: a231144642eabe64691435d0172b660b, type: 3}
  type: MAGE
  description: Malrah, known as the Eradicator, commands terrifying dark powers within
    the Ring of Titans, unleashing devastating damage upon her adversaries with ruthless
    efficiency. However, her strength comes with a cost, as she requires periods
    of downtime to replenish her energies. Despite her formidable prowess, Malrah
    is vulnerable to interruptions while casting spells, and her lack of mobility
    leaves her exposed to swift opponents. In the arena, her strategy revolves around
    swift strikes and calculated bursts of power, making her a force to be reckoned
    with for those who dare to challenge her domain.
  passiveIcon: {fileID: 21300000, guid: f0b5a859b7b719944901cbed811dcfe3, type: 3}
  passiveName: Shadow Resistance
  passiveDescription: Every time you successfully cast a spell, gain a buff that
    reduces the magic damage you take by <b>2%</b>. Stacks up to <b>5</b> times.
