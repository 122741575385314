import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc12",
  "Nov 3, 2024",
  `
- General
  - Improvements
    - Fixed an issue causing the Soul Leak talent to crash the game when it bounced damage into another shield with Soul Leak
    - Kill Shot now glows when it is active (target below 20% HP)
    - Added the stun duration to the tooltip of Crystal Blast
    - Fixed an issue where your whole game would break if you got a match while previewing a shop item
    - Fixed a couple of bugs with the chat system
- Balance
  - General
    - The powerup orbs have been removed and replaced with a general dampening system. After 2 minutes of game time, a 1% multiplicative healing/shielding reduction will apply every 6 seconds to everyone in the match.
      - The powerup orbs remain in CTF.
  - Crystalmancer
    - Crystalmancer's "Crystal Blast" is now instant cast (was: 1 second cast).
`
);

export default Notes;
