import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc14",
  "Nov 8, 2024",
  `
- General
  - Improvements
    - Added new Skin cosmetics - 2 appear in the shop each day
    - Added 3x exp for 3v3s
    - Made the tier 2 bow cosmetics larger
    - Tons of server hotfixes and performance improvements (mostly already hotfixed on 1.0.0rc13)
    - Some more tooltip fixes/improvements
    - Added support for translations for spells, talents, and buffs
      - Currently only Chinese Traditional & Simplified is supported, thanks to 日暮 who translated them
      - It's using your system language to pick the language at the moment
      - If you're interested in helping to translating to a language you know, please DM me on Discord
    - Fixed an issue where the mature language filter wouldn't apply to player names in chat messages
    - Sped up animation times ~2x for Smoke Bomb (Assassin) and Soul Recovery (Soulstealer)
  - Bug fixes
    - Fixed an issue where Multishot (Marksman) could target invisible units
    - Fixed an issue with the target/focus mouseover keybinds when hovering over UI elements
`
);

export default Notes;
