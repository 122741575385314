import { PageLayout } from "./PageLayout";
import titanRawData from "../../../ring-of-titans/Assets/Data/UnitTypes/*.asset";
import spellRawData from "../../../ring-of-titans/Assets/Data/Spells/**/*.asset";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "./LandingPage";
import { Avatar, Card, Flex, Grid, IconButton, Text } from "@radix-ui/themes";

const flattenedSpellList = Object.keys(spellRawData)
  .filter((x) => x != "TemplateSpellData")
  .flatMap((topKey) => {
    return Object.values(spellRawData[topKey]);
  })
  .map((x) => ({
    ...x.MonoBehaviour,
    usableBy: parseUsableByList(x.MonoBehaviour.usableBy),
    getImport: x.getImport,
  }));

function parseUsableByList(unityHexListString) {
  if (unityHexListString == null) {
    return [];
  }
  return unityHexListString
    .match(/.{1,8}/g)
    .map((x) => parseInt(x.substring(1).split("").reverse().join(""), 16));
}

const titanInfoLookup = {};
Object.keys(titanRawData).forEach((key) => {
  const titanInfo = titanRawData[key];
  const classId = titanInfo.MonoBehaviour.heroClass;

  titanInfoLookup[classId] = titanInfo;
});

function Spell({ icon, name, castTime, cooldown, description }) {
  return (
    <Card>
      <Flex gap="2">
        <div
          style={{
            width: 80,
            height: 80,
            flexShrink: 0,
            borderRadius: 8,
            backgroundImage: "url(" + icon + ")",
            backgroundSize: "100%",
          }}
        />
        <div>
          <div style={{ textTransform: "uppercase", fontSize: 22 }}>{name}</div>
          <div
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: 14,
              fontWeight: "bold",
              marginBottom: 8,
            }}
          >
            <div>
              {castTime == null
                ? null
                : castTime === 0
                ? "Instant cast"
                : castTime + "s cast"}
            </div>
            <div>
              {cooldown != null && cooldown > 0
                ? cooldown + "sec cooldown"
                : null}
            </div>
          </div>
          <pre
            style={{
              margin: 0,
              whiteSpace: "pre-wrap",
              fontFamily: '"Montserrat", sans-serif',
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </Flex>
    </Card>
  );
}

function SpellList(props) {
  const titanSpells = flattenedSpellList
    .filter((x) => x.usableBy.indexOf(props.classId) !== -1)
    .filter((x) => x.getImport(x.icon))
    .filter(
      (x) =>
        !x.isHidden && !x.isInternal && x.spellName.toLowerCase() !== "mount"
    );

  const spells = titanSpells.map((x) => {
    return (
      <Spell
        icon={x.getImport(x.icon)}
        name={x.spellName}
        castTime={x.castTime}
        cooldown={x.cooldown}
        description={x.description}
      />
    );
  });

  return (
    <Grid columns="2" gap="3" width="auto">
      {spells}
    </Grid>
  );
}

function TitanInfo(props) {
  const titanInfo = titanInfoLookup[props.classId];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <Flex align="center" gap="2">
        <Link
          to="/titans"
          style={{ color: "#e9f1e9", marginRight: 8, fontSize: 24 }}
        >
          <i className="fas fa-arrow-left" />
        </Link>
        <Avatar
          src={titanInfo.getImport(titanInfo.MonoBehaviour.icon)}
          size="6"
        />
        <Flex direction="column">
          <h3 style={{ margin: 0, textTransform: "uppercase" }}>
            {titanInfo.MonoBehaviour.unitSpec}
          </h3>
          <h1 style={{ margin: 0, textTransform: "uppercase" }}>
            {titanInfo.MonoBehaviour.unitName}
          </h1>
        </Flex>
      </Flex>
      <Card>
        <div style={{ fontFamily: '"Montserrat", sans-serif' }}>
          {titanInfo.MonoBehaviour.description}
        </div>
      </Card>
      <Spell
        icon={titanInfo.getImport(titanInfo.MonoBehaviour.passiveIcon)}
        name={"Passive: " + titanInfo.MonoBehaviour.passiveName}
        description={titanInfo.MonoBehaviour.passiveDescription}
      />
      {/* <h2 style={{ margin: 0, marginTop: 20, textTransform: "uppercase" }}>
        SPELLS
      </h2> */}
      <SpellList {...props} />
    </div>
  );
}

function TitanButton(props) {
  return (
    <Button style={{ padding: 0, borderRadius: 8 }}>
      <div
        style={{
          width: 172,
          height: 172,
          position: "relative",
          borderRadius: 8,
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={props.onClick}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundImage:
              "url(" +
              props.titan.getImport(props.titan.MonoBehaviour.icon) +
              ")",
            backgroundSize: "100%",
            zIndex: -1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 24,
            textShadow: "0 0 5px #444",
          }}
        >
          <div style={{ fontSize: 18 }}>
            {props.titan.MonoBehaviour.unitSpec}
          </div>
          <div>{props.titan.MonoBehaviour.unitName}</div>
        </div>
      </div>
    </Button>
  );
}

export default function Titans() {
  const params = useParams();
  const navigate = useNavigate();

  if (params.titan != null) {
    const selectedTitanKey = Object.keys(titanRawData).find((key) => {
      const data = titanRawData[key];
      return (
        data.MonoBehaviour.unitSpec.toLowerCase() ==
          params.titan.toLowerCase() && !key.toLowerCase().includes("tutorial")
      );
    });

    const selectedTitanId =
      titanRawData[selectedTitanKey].MonoBehaviour.heroClass;

    return (
      <PageLayout label={params.titan}>
        <TitanInfo classId={selectedTitanId} />
      </PageLayout>
    );
  }

  const sortedTitanData = Object.keys(titanRawData);
  sortedTitanData.sort((a, b) => {
    return (
      titanRawData[a].MonoBehaviour.heroClass -
      titanRawData[b].MonoBehaviour.heroClass
    );
  });

  const buttons = sortedTitanData
    .filter((key) => {
      const data = titanRawData[key];
      return !key.toLowerCase().includes("tutorial");
    })
    .map((key) => {
      const data = titanRawData[key];
      return (
        <TitanButton
          titan={data}
          onClick={() =>
            navigate("/titan/" + data.MonoBehaviour.unitSpec.toLowerCase())
          }
        />
      );
    });

  return (
    <PageLayout label="Titans">
      <div
        style={{
          display: "flex",
          gap: 20,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {buttons}
      </div>
    </PageLayout>
  );
}
