%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: SpecialPowerupOrbHealingReduction
  m_EditorClassIdentifier: 
  unitModel: {fileID: 3737948953826524632, guid: 615a8e7b4d8d0894289a2cac9f9e4460,
    type: 3}
  heroClass: 20
  isHealer: 0
  isInternal: 1
  level: 0
  attackSpeed: 1
  baseHP: 75
  unitName: Powerup Orb
  unitSpec: Healing Reduction
  icon: {fileID: 2800000, guid: a955b06f5a0d13c48a78d6531eded5ac, type: 3}
  backgroundColor: {r: 0.8588235, g: 0.8588235, b: 0.854902, a: 1}
  textColor: {r: 0.4, g: 0.4, b: 0.4, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HEALER
  description: 
  passiveIcon: {fileID: 21300000, guid: d3857588363cc674ea1157723c39247f, type: 3}
  passiveName: 
  passiveDescription: 
