import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc16",
  "Nov 13, 2024",
  `
- General
  - General
    - You're no longer allowed to queue for any bracket with multiple of the same Titan (e.g. double Assassin).
  - Bug fixes
    - Fixed an issue where units would occasionally be invisible (already hotfixed).
- Balance
  - General
    - Dev Note: The intention of the balance changes in 1.0.0rc16 is to:
      - Attempt to close out games quicker without having to go deep into dampening.
      - Give double DPS compositions a better chance in 2v2.
      - Rewarding smart & strategic cooldown trades rather than encouraging just pressing defensives whenever they are available.
    - Master of Battle can no longer be used while Stunned, Silenced or Incapacitated.
    - Enhanced Resilience now lasts 8 seconds (down from 10 seconds).
    - The cooldown of Enhanced Resilience has been increased to 80 seconds (up from 60 seconds).
    - Toughness and Resolve (Stun/Incap reduction talents) have had their effects reduced to 20% (down from 25%).
  - Cleric
    - Blessed Light healing has been increased by 33%.
    - The Power Burn debuff now lasts 45 seconds and reduces healing/shielding by 2.5% per stack (up from 30 sec, 2% per stack).
  - Lightbringer
    - Righteous Defense can no longer be cast on friendly targets other than the caster while Stunned, Silenced, or Incapacitated.
  - Plaguebringer
    - Curses now correctly apply the passive "Soul Essence".
`
);

export default Notes;
