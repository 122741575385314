%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: 17c14f584f7d29740952e55a51d6a1fe, type: 3}
  m_Name: PlayerDruid
  m_EditorClassIdentifier: 
  unitModel: {fileID: 3241138547371686306, guid: 2190831e07812fc469425810ebd0e015,
    type: 3}
  heroClass: 5
  isHealer: 1
  level: 0
  attackSpeed: 1
  baseHP: 192
  unitName: Airion
  unitSpec: Naturalist
  icon: {fileID: 2800000, guid: 4260c5888aba0a742ae51670f774d53d, type: 3}
  backgroundColor: {r: 0.6886792, g: 0.30881846, b: 0.10720008, a: 1}
  textColor: {r: 1, g: 1, b: 1, a: 1}
  typeIcon: {fileID: 21300000, guid: 423717ccb76a74e489e5d288c5af5780, type: 3}
  type: HEALER
  description: With a profound connection to the elements, Airion embodies the harmony
    between nature's nurturing embrace and the electrifying power of the storm. In
    combat, he orchestrates a delicate symphony, seamlessly blending his ability
    to harness the healing properties of the earth with the crackling fury of lightning.
    While his adversaries feel the sting of his electric assaults, Airion's team
    finds solace in his consistent support, as he ensures their resilience with the
    gentle caress of nature's mending touch. A true master of balance, Airion stands
    as a testament to the formidable union of nature's grace and tempestuous might
    within the arena's unforgiving confines.
  passiveIcon: {fileID: 21300000, guid: a0e16df8054af214b98b6aba1c12cbd7, type: 3}
  passiveName: Nature Mastery
  passiveDescription: Gain an additional 85% healing while in <b>Nature Mode</b>.
