export default function generateNotes(version, date, input) {
  const result = parseMarkdownList(input);
  result.label = version + " PATCH NOTES";
  result.date = date;

  return result;
}

function parseMarkdownList(markdown) {
  const lines = markdown.split("\n").filter((line) => line.trim());

  function parseLines(lines, level = 0) {
    const items = [];
    while (lines.length) {
      const line = lines[0];
      const currentIndent = line.search(/\S|$/); // Count leading spaces
      const isListItem = /^(\s*)[-*+]\s|^\s*\d+\.\s/.test(line); // Detect list items

      if (currentIndent < level) break; // End of current level

      if (isListItem && currentIndent === level) {
        lines.shift(); // Remove current line
        const label = line.trim().replace(/^[-*+]\s|^\d+\.\s/, ""); // Remove list marker
        const item = { label, children: [] };

        // Parse children if they exist
        const children = parseLines(lines, level + 2); // Increase level by 2 spaces for indentation
        if (children.length) {
          item.children = children;
        }

        items.push(item);
      } else {
        break;
      }
    }
    return items;
  }

  return { label: "root", children: parseLines(lines) };
}
