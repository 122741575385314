import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc9",
  "Oct 27, 2024",
  `
- General
  - Improvements
    - The matchmaking system now tries for a bit longer to find better matches, and will try to avoid placing solo queue players with teammates with far away ratings.
    - Damage dealt to yourself or teammates no longer counts on the end game screen.
    - Fixed the 3v3 tooltip claiming that it only allows solo queues.
    - Fixed a couple of tooltip typos.
    - Added a few new cosmetics to the shop pool.
- Balance
  - General
    - Lightning Storm reduced to 19 damage (down from 21) per tick.
    - Thorns now lasts 8 seconds (down from 10).
    - Thorns now ticks 3 times per second (down from 4 times per second).
    - Enhanced Power reduced to 15% (down from 20%) bonus damage.
    - Bandage now heals 20 per tick (up from 10).
    - Enhanced Resilience now reduces damage taken by 30% (up from 20%)
    - Toughness now reduces the duration of stuns by 25% (up from 20%).
    - Resolve now reduces the duration of Incapacitates by 25% (up from 20%).
    - Warmth now reduces the duration of slows and snares by 50% (up from 20%).
    - The "Explosive" talent now reduces the damage dealt by each explosion by 7% (up from 5%).
    - Bruiser talent "Resourceful" now always gives 1 combo points, without the bonus point when using 5cp.
    - Bruiser talent "Deep Wounds" now causes a 100% damage bleed from Shadow Blow (down from 150%).
    - Dark suppression now has a 45 sec cd (up from 40).
    - Naturalist Airion now automatically gets Nature Mode when loading into a match.
    - Stormcaller Airion now automatically gets Lightning Mode when loading into a match.
`
);

export default Notes;
