import { Card } from "@radix-ui/themes";

export function DevComment({ children }) {
  return (
    <Card style={{ fontFamily: '"Montserrat", sans-serif' }}>
      <b style={{ fontSize: 18 }}>Developer Note</b>
      <div>
        <i>{children}</i>
      </div>
    </Card>
  );
}
